import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { HeaderMenuBtn } from './header-menu-btn';
import { HeaderOptionsBtn } from './header-options-btn';
import { PhoneNumber } from './phone-number';
import { Menu } from './menu';
import { DEFAULT_HEADER_TEXT, headerTexts } from '../model/headerPathText';
import { SminexLogo } from '@/shared/ui/icons/Sminex';
import styles from './header.module.css';

export const Header = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const basePath = pathname.replace(`/${id}`, '');
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isMenuVisible ? 'hidden' : 'auto';
  }, [isMenuVisible]);

  const headerDescText: string = headerTexts[basePath] || DEFAULT_HEADER_TEXT;

  const handleMenuClose = () => {
    setIsMenuVisible(false);
  };

  return (
    <>
      <div className={`${styles.headerContainer} ${isMenuVisible ? styles.clicked : ''}`}>
        <div className={styles.menuWithLogoCont}>
          <div className={`${styles.menuWithLogoWrap} ${isMenuVisible ? styles.clicked : ''}`}>
            <HeaderMenuBtn onClickFunc={() => setIsMenuVisible(!isMenuVisible)} isMenuVisible={isMenuVisible} />
          </div>
          <div className={`${styles.sminexLogoWrap} ${isMenuVisible ? styles.clicked : ''}`}>
            <SminexLogo />
          </div>
        </div>
        {!isMenuVisible && <div className={styles.headerDesc}>{headerDescText}</div>}
        <div className={styles.optionsAndNumberBlock}>
          <div className={`${styles.headerOptionsWrap} ${isMenuVisible ? styles.clicked : ''}`}>
            Выбрать недвижимость
            <HeaderOptionsBtn />
          </div>
          <PhoneNumber phone="+7 495 324 72 11" isMenuVisible={isMenuVisible} />
        </div>
      </div>
      <div className={`${styles.menuWrapper} ${isMenuVisible ? styles.visible : styles.hidden}`}>
        <Menu onClose={handleMenuClose} />
      </div>
    </>
  );
};
